<template>
	<div class="flex flex-col gap-4">
		<!-- <p>bread crumb</p> -->
		<div class="flex flex-col gap-4 lg:flex-row lg:items-start">
			<div class="bg-white rounded-lg flex flex-col w-full max-w-[600px]">
				<div class="p-[16px] border_b">
					<h1 class="text-base text-[#000005] font-medium">Shuttle Information</h1>
				</div>

				<div class="flex flex-col px-[16px]">
					<template v-for="n in list" >
						<div :key="n.title" v-if="n.title == 'Start date' || n.title == 'Date created'" class="py-[16px] border_b flex items-center justify-between gap-4">
							<p class="uppercase text-sm font-medium text-[#6E717C]">{{ n.title }}</p>
							<p class="text-sm font-medium text-[#000005]">{{ moment(n.val).format('DD/MM/YYYY') }}</p>
						</div>
						<div :key="n.title" v-else-if="n.title == 'Status'" class="py-[16px] flex items-center justify-between gap-4 border_b">
							<p class="uppercase text-sm font-medium text-[#6E717C]">{{ n.title }}</p>
							<p class="text-sm font-medium text-white py-1 px-2 rounded"
								:class="[n.val == 'active' ? ' bg-[#109352]' : ' bg-[#E13D45]']"
							>{{ n.val == 'active' ? 'Active' : n.val == 'deactivated' ? 'Deactivated' :'Inactive' }}</p>
						</div>
						<template v-else-if="n.title == 'Rejection reason'">
							<div :key="n.title" v-if="!isActive" class="py-[16px] flex items-center justify-between gap-4 border_t">
								<p class="uppercase text-sm font-medium text-[#6E717C]">{{ n.title }}</p>
								<p class="text-sm font-medium text-[#000005]">{{ n.val }}</p>
							</div>
						</template>
						
						<div :key="n.title" v-else class="py-[16px] border_b flex items-center justify-between gap-4">
							<p class="uppercase text-sm font-medium text-[#6E717C]">{{ n.title }}</p>
							<p class="text-sm font-medium text-[#000005]">{{ n.val }}</p>
						</div>
					</template>
				</div>
			</div>

			<div class=" flex gap-[16px] flex-col w-full max-w-[500px]">
				<div class="flex p-[16px] items-center h-fit gap-6 bg-white rounded-lg">
					<stepper :Height="110" :steps="[isActive, false, false]" />
					<div class="grid grid-rows-3 gap-4 flex-grow">
						<div class=" flex items-center justify-between w-full">
							<p class="text-sm text-[#101211] font-medium">Shuttle request 
								<span v-if="!isActive" class="text-[10px] px-2 py-1 rounded bg-[#E7C805] text-white">Pending</span>
							</p>
							<button v-if="!isActive" class="btn w-fit bg-black text-white py-1 px-2" @click="acceptRequest('active', 'accept')">Accept</button>
						</div>
						<div class=" flex items-center justify-between w-full">
							<p class="text-sm font-medium" :class="!isActive ? 'text-[#B9BCC8]' : 'text-[#101211]'">Route planning 
								<span v-if="isActive" class="text-[10px] px-2 py-1 rounded bg-[#E7C805] text-white">Pending</span>
							</p>
							<button v-if="isActive" class="btn w-fit bg-black text-white py-1 px-2">Create route</button>
						</div>
						<div class=" flex items-center justify-between w-full">
							<p class="text-sm font-medium" :class="true ? 'text-[#B9BCC8]' : 'text-[#101211]'">Assign staff  
								<span v-if="false" class="text-[10px] px-2 py-1 rounded bg-[#E7C805] text-white">Pending</span>
							</p>
							<button v-if="false" class="btn w-fit bg-black text-white py-1 px-2">Accept</button>
						</div>
						
					</div>
				</div>

				<div v-if="false" class="flex flex-col p-[16px] h-fit gap-[16px] bg-white rounded-lg">
					<div class="flex flex-col gap-2 text-sm">
						<p class="text-[#09090F]">Work branch:</p>
						<div class="flex gap-2 flex-wrap">
							<p class="p-2 rounded text-[#101211] font-medium bg-[#EFF2F7]">hello</p>
						</div>
					</div>
					<div class="flex flex-col gap-2 text-sm">
						<p class="text-[#09090F]">Work shift:</p>
						<div class="flex gap-2 flex-wrap">
							<p class="p-2 rounded text-[#101211] font-medium bg-[#EFF2F7]">hello</p>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script setup>
import {useRoute} from 'vue-router/composables'
import {ref, computed,inject} from 'vue'
import moment from 'moment'
import stepper from '../../components/stepper.vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'
import { extractErrorMessage } from '@/utils/helpers'

const request = inject('requestData')
const acceptRequest = inject('modifyRequest')

const route = useRoute()
const list = computed(() => {
	return [
		{title: 'Company', val: request.value.corporate.corporate_name},
		{title: 'Service Option', val: request.value?.sharing_type},
		{title: 'Payment option', val: request.value?.payment_type},
		{title: 'Staff size', val: request.value?.users.length},
		{title: 'Staff filter option', val: 'N/A'},
		{title: 'Duration', val: request.value?.duration},
		{title: 'Start date', val: request.value?.start_date},
		{title: 'Date created', val: request.value?.created_at},
		{title: 'Status', val: request.value?.status},
		{title: 'Rejection reason', val: request.value?.rejection_reason || 'N/A'}
	]
})

const isActive = computed(() => {
	return request.value.status == 'active' ? true : false
})


</script>

<style scoped>
h1,p,h2 {
	margin: 0;
}
.border_b{
	border-bottom: 1px solid #E5E9F2
}
.border_t{
	border-top: 1px solid #E5E9F2
}
</style>